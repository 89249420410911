import '@/components/variables.css'
import '@/components/global.css'

import React from 'react'

import Footer from '@/components/footer'
import Navigation from '@/components/navigation'

type PropsType = {
    title: string
}

const Template: React.FC<PropsType> = ({ children, title }) => (
  <>
    <Navigation title={title} />
    <main>{children}</main>
    <Footer />
  </>
)

export default Template
