import { Link } from 'gatsby'
import React from 'react'

import * as styles from '@/components/navigation/navigation.module.scss'

type PropsType = {
    title: string
}

const Index: React.FC<PropsType> = ({ title }) => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/" className={styles.logo__link}>
      <span className={styles.logo} />
      <span className={styles.navigation__item}>{title}</span>
    </Link>
    <ul className={styles.navigation}>
      <li className={styles.navigation__item}>
        <Link className={styles.navigation__link} target="_blank" to="https://github.com/konu96" activeClassName="active">
          <img className={styles.navigation__image} src="/assets/github.png" alt="GitHubのアイコン" />
          <span>GitHub</span>
        </Link>
      </li>
    </ul>
  </nav>
)

export default Index
