import React from 'react'

type Props = {
    as: string
}

const Container: React.FC<Props> = ({ children , as}) => {
    const Tag = as

    return (
    // @ts-ignore
      <Tag
        style={{
          maxWidth: 'var(--size-max-width)',
          margin: '0 auto',
          padding: 'var(--space-2xl) var(--size-gutter)',
        }}
      >
        {children}
    </Tag>
    )
}

export default Container