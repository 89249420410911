import React from 'react'
import { Helmet } from 'react-helmet'

type PropsType = {
  description: string
  meta: {
    name: string
    content: string
  }[]
  title: string
  image: string
}

const Seo: React.FC<PropsType> = ({ description = '', meta = [], title, image }) => {
  const metaDescription = description

  return (
    <Helmet
      htmlAttributes={{lang: 'ja'}}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: image,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default Seo
