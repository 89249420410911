import React from 'react'

import * as styles from '@/components/tags/tags.module.css'

const Index: React.FC<{tags: string[]}> = ({ tags }) => {
    if (tags.length === 0) {
        return <></>
    }

    return (
        <small className={styles.tags}>
            {tags.map((tag: string) => (
                <div key={tag} className={styles.tag}>
                    {tag}
                </div>
            ))}
        </small>
    )
}

export default Index